import Sidebar from 'components/Sidebar/Sidebar.js'
import CalledsTable from "../views/Calleds/CalledsTable";

export default function ListCalleds() {
  return (
    <>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-100 h-screen ">
        <CalledsTable />
      </div>
    </>
  )
}