import {useEffect, useState} from "react";
import api from "../../lib/api";
import ModalEditUser from "../../components/Modals/ModalEditUser";

export default function EditUserTable(){
    const [data, setData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/user/get-all-users');
                // console.log(response.data);
                setData(response.data);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        };

        fetchData();
    }, []);

    const openUserModal = (user) => {
        setSelectedUser(user);
        setOpenModal(true);
    }

    const deleteUser = (user) => {
        api.delete(`/user/delete-user/${user.id}`).then(response => {
            console.log(response.data);
            const newData = data.filter(item => item.id !== user.id);
            setData(newData);
        })
    }

    return (
        <>
            <ModalEditUser user={selectedUser} isOpen={openModal} onClose={() => setOpenModal(false)} />

            {data.length === 0 && (
                <div className="flex items-center justify-center h-screen">
                    <h1 className="text-2xl">Nenhum usuário encontrado</h1>
                </div>
            )}

            <div className="mx-auto bg-white shadow-md rounded-lg p-4 overflow-x-auto" style={{maxWidth: '1100px'}}>
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-3 px-6 text-left">Nome</th>
                            <th className="py-3 px-6 text-left">Email</th>
                            <th className="py-3 px-6 text-left">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td className="py-3 px-6 text-left">{item.nome}</td>
                                <td className="py-3 px-6 text-left">{item.email}</td>
                                <td className="py-3 px-6 text-left flex">
                                    <button
                                        onClick={() => openUserModal(item)}
                                        className="block w-full text-left px-4 py-2 mr-2 text-white rounded-lg bg-black hover:bg-gray-100"
                                        >Editar</button>
                                    <button
                                        onClick={() => deleteUser(item)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg">Excluir</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}