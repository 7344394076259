import React, {useEffect, useState} from "react";
import api from "../../lib/api";
import Swal from "sweetalert2";

export default function ModalEditUser({user, isOpen, onClose}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [form, setForm] = useState({
        name: '',
        email: '',
        senha: '',
        cpf: '',
        bairro: '',
        rua: '',
        numero: '',
        codigo_ddd: ''
    })
    useEffect(() => {
        if (isOpen && user?.id) {
            api.get(`/user/${parseInt(user.id)}`).then((response) => {
                setForm({
                    name: response.data.name,
                    email: response.data.email,
                    senha: response.data.senha,
                    cpf: response.data.cpf,
                    bairro: response.data.bairro,
                    rua: response.data.rua,
                    numero: response.data.numero,
                    codigo_ddd: response.data.codigo_ddd
                });
            });
        }
    }, [isOpen, user]); // Executa apenas quando 'isOpen' ou 'user' mudam


    const handleEditUser = (e) => {
        Swal.fire({
            title: "Deseja editar usuario",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                api.put(`/user/update-user/${user.id}`, form).then((response) => {
                    Swal.fire({
                        title: "Usuario editado com sucesso",
                        icon: "success",
                    }).then(() => {
                        window.location.reload();
                    });
                });
            }
        });
    }

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex w-full items-center justify-center h-screen bg-black opacity"
                     style={{backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(2px)'}}>
                    <div className="relative my-6 mx-auto" style={{maxWidth: '1000px'}}>
                        <div
                            className="border-0 rounded-lg p-10 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-full">
                            {/*header*/}
                            <div
                                className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">Editar Usuário</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={onClose}
                                >
                                    <span
                                        className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            {/*body*/}
                            <form className="p-10" style={{padding:"10px"}}>
                                <div className="relative p-6 flex-auto">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                            Nome
                                        </label>
                                        <input
                                            value={form.name}
                                            onChange={(e) => setForm((prev) => ({...prev, name: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="name" type="text" placeholder="Nome"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            value={form.email}
                                            onChange={(e) => setForm((prev) => ({...prev, email: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email" type="email" placeholder="Email"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="senha">
                                            Senha
                                        </label>
                                        <input
                                            value={form.senha}
                                            onChange={(e) => setForm((prev) => ({...prev, senha: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="senha" type="password" placeholder="Senha"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
                                            CPF
                                        </label>
                                        <input
                                            value={form.cpf}
                                            onChange={(e) => setForm((prev) => ({...prev, cpf: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="cpf" type="text" placeholder="CPF"/>
                                    </div>
                                    <div className=" mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bairro">
                                            Bairro
                                        </label>
                                        <input
                                            value={form.bairro}
                                            onChange={(e) => setForm((prev) => ({...prev, bairro: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="bairro" type="text" placeholder="Bairro"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rua">
                                            Rua
                                        </label>
                                        <input
                                            value={form.rua}
                                            onChange={(e) => setForm((prev) => ({...prev, rua: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="rua" type="text" placeholder="Rua"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="numero">
                                            Numero
                                        </label>
                                        <input
                                            value={form.numero}
                                            onChange={(e) => setForm((prev) => ({...prev, numero: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="numero" type="text" placeholder="Numero"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2"
                                               htmlFor="codigo_ddd">
                                            Código DDD
                                        </label>
                                        <input
                                            value={form.codigo_ddd}
                                            onChange={(e) => setForm((prev) => ({...prev, codigo_ddd: e.target.value}))}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="codigo_ddd" type="text" placeholder="Código DDD"/>
                                    </div>
                                </div>
                            </form>
                            {/*footer*/}
                            <div
                                className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={onClose}
                                >
                                    Fechar
                                </button>
                                <button
                                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={handleEditUser}
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

}