import Sidebar from 'components/Sidebar/Sidebar'
import React from 'react'
import PlanCosifTable from 'views/plan-cosif/PlanCosifTable'

const ListPlanCosif = () => {
  return (
    <>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-200 h-screen">
        <PlanCosifTable />
      </div>
    </>
  )
}

export default ListPlanCosif
