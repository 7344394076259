import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";

// layouts

import Auth from "layouts/Auth.js";
import PrivateRoutes from "views/auth/private-routes.js";

// views without layouts

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import CreateDeclaration from "layouts/CreateDeclaration.js";
import ListDeclarations from "layouts/ListDeclarations";
import ListPlanCosif from "layouts/ListPlanCosif";
import ConfigCosif from "layouts/ConfigCosif";
import ConfiguredPlans from "layouts/ConfiguredPlans";
import Landing from "views/Landing";
import {ListDeclarationErrors} from "./layouts/ListDeclarationErrors";
import CreateCityHall from "./layouts/CreateCityHall";
import EditUserTable from "./views/user/EditUserTable";
import ListUsers from "./layouts/ListUsers";
import ListCalleds from "./layouts/ListCalleds";
import FormCalled from "./components/Forms/FormCalled";
import CreateCalled from "./layouts/CreateCalled";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      // {
      //   path: "/admin",
      //   element: <Admin />,
      // },
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/create-city-hall",
        element: <CreateCityHall />,
      },
      {
        path: "/admin/settings",
        element: <Settings />,
      },
      {
        path: "/admin/tables",
        element: <Tables />,
      },
      {
        path: "/admin/declaracoes",
        element: <CreateDeclaration />,
      },
      {
        path: "/admin/list-declarations",
        element: <ListDeclarations />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/list-plan-cosif",
        element: <ListPlanCosif />,
      },
      {
        path: "/admin/config-cosif",
        element: <ConfigCosif />,
      },
      {
        path: "/admin/configured-plans",
        element: <ConfiguredPlans />,
      },
      {
        path: "/declarations-errors",
        element: <ListDeclarationErrors />,
      },
      {
        path: "/list-users",
        element: <ListUsers />,
      },
      {
        path:"/calleds",
          element: <ListCalleds />
      },
        {
          path:"/create-calleds",
            element: <CreateCalled />
        }
    ],
  },
  {
    path: "auth",
    element: <Auth />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
