import React, { useEffect, useState } from 'react';
import api from 'lib/api';
import ModalDeclaration from 'components/Modals/ModalDeclaration';
import ReactPaginate from 'react-paginate';

const DeclarationsTable = () => {
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDeclaration, setSelectedDeclaration] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('declaration-0000/get-declarations');
        // console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, []);

  const openDeclarationModal = (declaration) => {
    setSelectedDeclaration([declaration]);
    setOpenModal(true);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    <>
      <ModalDeclaration isOpen={openModal} declarations={selectedDeclaration} onClose={() => setOpenModal(false)} />

      {data.length === 0 && (
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-2xl">Nenhuma declaração encontrada</h1>
        </div>
      )}

      <div className="mx-auto bg-white shadow-md rounded-lg p-4 overflow-x-auto" style={{ maxWidth: '1100px' }}>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-3 px-6 text-left">CNPJ</th>
              <th className="py-3 px-6 text-left">Razão Social</th>
              <th className="py-3 px-6 text-left">Início Competência</th>
              <th className="py-3 px-6 text-left">Fim Competência</th>
              <th className="py-3 px-6 text-left">Código Município</th>
              <th className="py-3 px-6 text-left">Ações</th>
              <th className="py-3 px-6 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-3 px-6 text-left">{item.cnpj}</td>
                <td className="py-3 px-6 text-left">{item.razao_social}</td>
                <td className="py-3 px-6 text-left">{item.ano_mes_incio_competencia}</td>
                <td className="py-3 px-6 text-left">{item.ano_mes_fim_competencia}</td>
                <td className="py-3 px-6 text-left">{item.codigo_municipio}</td>
                <td className="py-3 px-6 text-left relative">
                  <button
                    onClick={() => openDeclarationModal(item)}
                    className="block w-full text-left px-4 py-2 text-white rounded-lg bg-black hover:bg-gray-100"
                  >
                    Detalhes
                  </button>
                </td>
                <td className="py-3 px-6 text-left">
                  {item.Declaracao.map((declaration, index) => (
                    <div key={index}>
                      {declaration.status_situacao !== 1 && (
                        <span className="text-red-500">Pendente</span>
                      )}
                      {declaration.status_situacao === 1 && (
                        <span className="text-green-500">Aprovado</span>
                      )}
                      {declaration.status_situacao === 2 && (
                        <span className="text-yellow-500">Reprovado</span>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Próxima'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex justify-center mt-4 space-x-2'}
          pageClassName={'mx-1'}
          pageLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
          previousClassName={'mx-4'}
          previousLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
          nextClassName={'mx-4'}
          nextLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
          activeClassName={'text-gray'}
          activeLinkClassName={'px-4 m-2 py-2 border rounded-lg text-white bg-black'}
        />
      </div>
    </>
  );
};

export default DeclarationsTable;
