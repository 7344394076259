import Sidebar from "components/Sidebar/Sidebar";
import api from "lib/api";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withMask } from "use-mask-input";

export default function Register() {

  const [bancos, setBancos] = useState([]);

  const [formData, setFormData] = useState({
    cpf: "",
    nome: "",
    senha: "",
    email: "",
    created_at: "2024-04-14T12:00:00Z",
    codigo_ddd: "",
    numero: "",
    id_pais: 1,
    cep: "",
    estado: "",
    id_cidade: 1,
    bairro: "",
    rua: "",
    casa: "",
    complemento: "",
    trocar_senha: true,
    tipo_envio_codigo: "email",
    codigo_troca_senha: "",
    tempo_expiracao_codigo: "",
    data_envio_codigo: "2024-04-14T12:00:00Z",
    ativo: true,
    tipo_usuario: "GERENTE",
    id_usuario_inativou: null,
    motivo_inativacao: "",
    data_nascimento: "1990-01-01T00:00:00Z",
    id_prefeitura: 1,
    nome_instituicao: "",
    cpf_cnpj: "",
    inscricao_estadual: "",
    inscricao_municipal: "",
    cep_instituicao: "",
    id_cidade_instituicao: 1,
    estado_instituicao: "",
    bairro_instituicao: "",
    rua_instituicao: "",
    numero_instituicao: "",
    complemento_instituicao: "",
    email_instituicao: "",
    codigo_ddd_instituicao: "",
    telefone_instituicao: "",
    id_banco: null,
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };
  const preparedData = {
    ...formData,
    casa: parseInt(formData.casa, 10),
    cpf: formData.cpf.replace(/\D/g, ""),
    numero: formData.numero.replace(/\D/g, ""),
    cep: formData.cep.replace(/\D/g, ""),
  };

  const listBancos = async () => {
    await api.get("/banco").then((response) => {
      setBancos(response.data);
      console.log("Bancos:", response.data);
    });
  };

  React.useEffect(() => {
    listBancos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await api
      .post("/auth/register", preparedData)
      .then((response) => {
        Swal.fire({
            icon: "success",
            title: "Usuário registrado com sucesso!",
            text: "Você será redirecionado para a página de login",
            }).then(() => {
            window.location.href = "/register";
            });
      })
      .catch((error) => {
        console.error("Erro ao fazer login:", error);

        const swalError = withReactContent(Swal);
        return swalError.fire({
          icon: "error",
          title: "Erro ao registrar",
          text: error.response.data.message,
        });
      });
  };

  return (
    <>
      <Sidebar />
      <div className="flex items-center justify-center min-h-screen mt-10 bg-gray-100">
        <div className="w-full px-4 lg:w-6/12">
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
            <div className="px-6 py-6 mb-0 rounded-t">
              <div className="mb-3 text-center">
                <h6 className="text-sm font-bold text-blueGray-500">
                  Registrar usuários
                </h6>

                <div className="text-center btn-wrapper">
                  <img
                    src={require("assets/img/desif.png")}
                    alt="..."
                    className="mx-auto rounded-full"
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
              <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
              <div className="mb-3 font-bold text-center text-blueGray-400">
                <small>Cadastro de credênciais</small>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="nome"
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="nome"
                    placeholder="Nome"
                    value={formData.nome}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="cpf"
                  >
                    CPF
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="cpf"
                    ref={withMask({ mask: "999.999.999-99" })}
                    placeholder="CPF"
                    value={formData.cpf}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="email"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="email"
                    placeholder="E-mail"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="senha"
                  >
                    Senha
                  </label>
                  <input
                    type="password"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="senha"
                    placeholder="Senha"
                    value={formData.senha}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex w-full gap-24 mb-3">
                  <div className="pr-2">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="codigo_ddd"
                    >
                      DDD
                    </label>
                    <input
                      type="text"
                      className="w-10 px-3 py-3 mr-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      id="codigo_ddd"
                      ref={withMask({ mask: "99" })}
                      value={formData.codigo_ddd}
                      onChange={handleChange}
                      placeholder="DDD"
                    />
                  </div>
                  <div className="pl-2 ">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="numero"
                    >
                      Número de Telefone
                    </label>
                    <input
                      type="text"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      id="numero"
                      placeholder="Número de Telefone"
                      value={formData.numero}
                      ref={withMask({ mask: "99999-9999" })}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="cep"
                  >
                    CEP
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="cep"
                    placeholder="CEP"
                    ref={withMask({ mask: "99999-999" })}
                    value={formData.cep}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="estado"
                  >
                    Estado
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="estado"
                    placeholder="Estado"
                    value={formData.estado}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="id_cidade"
                  >
                    Cidade
                  </label>
                  <select
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="id_cidade"
                    value={formData.id_cidade}
                    onChange={handleChange}
                  >
                    <option value="1">Cidade 1</option>
                    <option value="2">Cidade 2</option>
                    <option value="3">Cidade 3</option>
                  </select>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="bairro"
                  >
                    Bairro
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="bairro"
                    placeholder="Bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="rua"
                  >
                    Rua
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="rua"
                    placeholder="Rua"
                    value={formData.rua}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="casa"
                  >
                    Número da Casa
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="casa"
                    placeholder="Número da Casa"
                    value={formData.casa}
                    onChange={handleChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="complemento"
                  >
                    Complemento
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="complemento"
                    placeholder="Complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="tipo_usuario"
                  >
                    Tipo de Usuário
                  </label>
                  <select
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    id="tipo_usuario"
                    value={formData.tipo_usuario}
                    onChange={handleChange}
                  >
                    <option value="ADMINISTRADOR">Administrador</option>
                    <option value="SUPORTE">Suporte</option>
                    <option value="GERENTE">Gerente</option>
                    <option value="PREFEITURA">Prefeitura</option>
                  </select>
                </div>
                {formData.tipo_usuario === "prefeitura" && (
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="id_prefeitura"
                    >
                      Prefeituras
                    </label>

                    <select
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      id="id_prefeitura"
                      value={formData.id_prefeitura}
                      onChange={handleChange}
                    >
                      <option value="1">Prefeitura 1</option>
                      <option value="2">Prefeitura 2</option>
                      <option value="3">Prefeitura 3</option>
                    </select>
                  </div>
                )}
                {formData.tipo_usuario === "GERENTE" && (
                  <div className="relative w-full mb-3">
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="nome_instituicao"
                      >
                        Nome da Instituição
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="nome_instituicao"
                        placeholder="Nome da Instituição"
                        value={formData.nome_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="cpf_cnpj"
                      >
                        CPF/CNPJ
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="cpf_cnpj"
                        placeholder="CPF/CNPJ"
                        value={formData.cpf_cnpj}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="inscricao_estadual"
                      >
                        Inscrição Estadual
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="inscricao_estadual"
                        placeholder="Inscrição Estadual"
                        value={formData.inscricao_estadual}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="inscricao_municipal"
                      >
                        Inscrição Municipal
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="inscricao_municipal"
                        placeholder="Inscrição Municipal"
                        value={formData.inscricao_municipal}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="cep_instituicao"
                      >
                        CEP
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="cep_instituicao"
                        placeholder="CEP"
                        value={formData.cep_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="estado_instituicao"
                      >
                        Estado
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="estado_instituicao"
                        placeholder="Estado"
                        value={formData.estado_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="id_cidade_instituicao"
                      >
                        Cidade
                      </label>

                      <select
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="id_cidade_instituicao"
                        value={formData.id_cidade_instituicao}
                        onChange={handleChange}
                      >
                        <option value="1">Cidade 1</option>
                        <option value="2">Cidade 2</option>
                        <option value="3">Cidade 3</option>
                      </select>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="bairro_instituicao"
                      >
                        Bairro
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="bairro_instituicao"
                        placeholder="Bairro"
                        value={formData.bairro_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="rua_instituicao"
                      >
                        Rua
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="rua_instituicao"
                        placeholder="Rua"
                        value={formData.rua_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="numero_instituicao"
                      >
                        Número
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="numero_instituicao"
                        placeholder="Número"
                        value={formData.numero_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="complemento_instituicao"
                      >
                        Complemento
                      </label>

                      <input
                        type="text"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="complemento_instituicao"
                        placeholder="Complemento"
                        value={formData.complemento_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="email_instituicao"
                      >
                        E-mail
                      </label>

                      <input
                        type="email"
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="email_instituicao"
                        placeholder="E-mail"
                        value={formData.email_instituicao}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <div className="flex w-full gap-24 mb-3">
                        <div className="pr-2">
                          <label
                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                            htmlFor="codigo_ddd_instituicao"
                          >
                            DDD
                          </label>
                          <input
                            type="text"
                            className="w-10 px-3 py-3 mr-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                            id="codigo_ddd_instituicao"
                            ref={withMask({ mask: "99" })}
                            value={formData.codigo_ddd_instituicao}
                            onChange={handleChange}
                            placeholder="DDD"
                          />
                        </div>
                        <div className="pl-2 ">
                          <label
                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                            htmlFor="telefone_instituicao"
                          >
                            Número de Telefone
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                            id="telefone_instituicao"
                            placeholder="Número de Telefone"
                            value={formData.telefone_instituicao}
                            ref={withMask({ mask: "99999-9999" })}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                        htmlFor="id_banco"
                      >
                        Banco
                      </label>

                      <select
                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        id="id_banco"
                        value={formData.id_banco}
                        onChange={handleChange}
                      >
                        <>
                          <option value="" selected>Selecione um banco</option>
                          {bancos.map((banco) => (
                            <option key={banco.id} value={banco.id}>
                              {banco.codigo_banco + "-" + banco.nome}
                            </option>
                          ))}
                        </>
                      </select>
                    </div>
                  </div>
                )}

                <div className="mt-6 text-center">
                  <button
                    className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                    type="submit"
                  >
                    Criar Conta
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
