import Sidebar from 'components/Sidebar/Sidebar.js'
import FormCalled from "../components/Forms/FormCalled";

export default function CreateCalled() {
    return (
        <>
            <Sidebar />
            <div className="md:ml-64 bg-blueGray-100 h-screen ">
                <FormCalled />
            </div>
        </>
    )
}