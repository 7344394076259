import React from "react";

export function ModalErrorDeclaration({isOpen, errorsDeclaration, onClose}) {
    return (
        <>
            {isOpen && (
                <div
                    className="fixed inset-0 z-50 flex w-full items-center justify-center h-screen bg-black opacity"
                    style={{backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(2px)'}}
                >
                    <div className="relative my-6 mx-auto" style={{maxWidth: '1000px'}}>
                        <div
                            className="border-0 rounded-lg p-10 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-full">
                            {/*header*/}
                            <div
                                className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">Erros</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={onClose}
                                >
                                    <span
                                        className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto overflow-y-auto ml-6"
                                 style={{maxHeight: '400px', margin: '0px 10px'}}>
                                <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900"
                                                    id="modal-headline">
                                                    Erros da Declaração
                                                </h3>
                                                <div className="mt-2">
                                                    <div className="flex flex-col" style={{gap: '10px'}}>
                                                        {errorsDeclaration[0] && errorsDeclaration[0].map((error, index) => (
                                                            <div key={index} className="flex flex-row justify-between border-b-2 border-gray-200 p-2 border-2" style={{gap: '10px'}}>
                                                                <div className="flex flex-col">
                                                                    <span className="text-sm font-semibold">Linha: {error.numero_linha}</span>
                                                                    <span className="text-sm font-semibold">Tipo Registro: {error.tipo_registro}</span>
                                                                    <span className="text-sm font-semibold">Mensagem: {error.Erros.mensagem}</span>
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <h3>Solução</h3>
                                                                    <span className="text-sm font-semibold"> {error.Erros.solucao}</span>
                                                                </div>
                                                            </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            onClick={onClose}
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Fechar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
