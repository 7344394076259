import Sidebar from 'components/Sidebar/Sidebar'
import React from 'react'
import ConfiguredPlanTable from 'views/plan-cosif/ConfiguredPlanTable'

const ConfiguredPlans = () => {
  return (
    <>
    <Sidebar />
    <div>
        <ConfiguredPlanTable />
    </div>
    </>
  )
}

export default ConfiguredPlans
