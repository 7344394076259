import api from 'lib/api.js';
import React, {useState} from 'react'
import Swal from "sweetalert2";

function FormCalled() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState(null);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        console.log(event.target.value);
        setDescription(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('titulo', title);
        formData.append('descricao', description);

        console.log(formData);

        try {
            await api.post('/calleds', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            await Swal.fire({
                icon: 'success',
                title: 'Chamado enviado com sucesso!',
                text: 'Aguarde a análise do chamado',
            }).then(() => {
                    window.location.href = "/calleds";
                }
            )
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar o chamado',
                text: error.response.data.message,
            });
        }
    };


    return (
        <>
            <div className="container px-4 place-content-center m-auto justify-center">
                <div className="flex content-center items-center justify-center">
                    <div
                        className="w-full justify-center m-auto place-content-center items-center mt-20 lg:w-4/12 px-4 ">
                        <div
                            className="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 ">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div className="text-center mb-3">
                                    <h6 className="text-blueGray-500 text-sm font-bold">
                                        Abri um chamado
                                    </h6>
                                </div>
                                <div className="btn-wrapper text-center">
                                </div>
                                <hr className="mt-6 border-b-1 border-blueGray-300"/>
                            </div>
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <form onSubmit={handleSubmit}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Assunto
                                        </label>
                                        <input
                                            onChange={handleTitleChange}
                                            type="text"
                                            name={"titulo"}
                                            className={"border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"} placeholder={"Título"}/>
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Motivo
                                        </label>
                                        <textarea
                                            onChange={handleDescriptionChange}
                                            name={"descricao"}
                                            className={"border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"} placeholder={"Motivo"}/>

                                    </div>
                                    <button
                                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                        type="submit"

                                    >
                                        Enviar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormCalled
