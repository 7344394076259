import api from 'lib/api';
import React, { useEffect, useState } from 'react';

const ConfiguredPlanTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/plan-cosif/plans-consif-configured');
        setData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* ModalDeclaration component omitted for brevity */}
      {data.length === 0 ? (
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-2xl">Nenhuma declaração encontrada</h1>
        </div>
      ) : (
        <div className="mx-auto bg-white shadow-md rounded-lg p-4" style={{ maxWidth: '1100px' }}>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-3 px-6 text-left">Código da Conta</th>
                <th className="py-3 px-6 text-left">Código da Conta Superior</th>
                <th className="py-3 px-6 text-left">Número da Conta</th>
                <th className="py-3 px-6 text-left">Instituição Bancária</th>
                <th className="py-3 px-6 text-left">Prefeitura</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="py-3 px-6 text-left">{item.PlanoContaCosif.codigo_conta}</td>
                  <td className="py-3 px-6 text-left">{item.PlanoContaCosif.codigo_conta_superior}</td>
                  <td className="py-3 px-6 text-left">{item.PlanoContaCosif.numero_conta}</td>
                  <td className="py-3 px-6 text-left">{item.InstiuicaoBancaria.nome}</td>
                  <td className="py-3 px-6 text-left">{item.Prefeitura.nome_prefeitura}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ConfiguredPlanTable;
