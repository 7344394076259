import React, {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import api from "../../lib/api";
import ModalDeclaration from "../../components/Modals/ModalDeclaration";
import {ModalCalleds} from "../../components/Modals/ModalCalleds";

function CalledsTable() {

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [called, setCalled] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('calleds');
        // console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, []);

  const calledModal = (called) => {
    setCalled([called]);
    setOpenModal(true);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);


  return (
    <>
    <ModalCalleds isOpen={openModal} called={called} onClose={() => setOpenModal(false)} />

      {
    data.length === 0 && (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-2xl">Nenhuma declaração encontrada</h1>
      </div>
    )  
  }

  <div className="mx-auto bg-white shadow-md rounded-lg p-4 overflow-x-auto" style={{ maxWidth: '1100px' }}>
    <table className="min-w-full bg-white">
      <thead>
        <tr>
          <th className="py-3 px-6 text-left">Titulo</th>
          <th className="py-3 px-6 text-left">Data</th>
          <th className="py-3 px-6 text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        {currentPageData.map((called, index) => (
            <tr key={index} className="hover:bg-gray-200">
                <td className="py-3 px-6 text-left whitespace-nowrap">{called.titulo}</td>
                <td className="py-3 px-6 text-left whitespace-nowrap">{new Date(called.created_at).toLocaleDateString("pt-BR")}</td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                {called.resposta ? 'Respondido' : 'Em aberto'}
                </td>
                <td className="py-3 px-6 text-center">
                <button
                    onClick={() => calledModal(called)}
                    className="bg-blue-500 text-black px-4 py-2 rounded border"
                >
                    Detalhes
                </button>
                </td>
            </tr>
            ))}

      </tbody>
    </table>

    <ReactPaginate
      previousLabel={'Anterior'}
      nextLabel={'Próxima'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={Math.ceil(data.length / itemsPerPage)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={'flex justify-center mt-4 space-x-2'}
      pageClassName={'mx-1'}
      pageLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
      previousClassName={'mx-4'}
      previousLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
      nextClassName={'mx-4'}
      nextLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
      activeClassName={'text-gray'}
      activeLinkClassName={'px-4 m-2 py-2 border rounded-lg text-white bg-black'}
    />
  </div>
    </>
  )
}

export default CalledsTable
