import Sidebar from 'components/Sidebar/Sidebar.js'
import DeclarationsTable from 'views/declarations/DeclarationsTable'
import React from 'react'

function ListDeclarations() {

  return (
    <>

      <Sidebar />
      <div className="md:ml-64 bg-blueGray-200 h-screen">
        <DeclarationsTable />

      </div>

    </>
  )
}

export default ListDeclarations