import api from "../../lib/api";
import React, {useEffect, useState} from "react";
import ModalDeclaration from "../../components/Modals/ModalDeclaration";
import ReactPaginate from "react-paginate";
import {ModalErrorDeclaration} from "../../components/Modals/ModalErrorDeclaration";


const DeclarationErrors = () => {
    const [listErrors, setListErrors] = useState([])

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(10);

    const [openModal, setOpenModal] = useState(false);
    const [selectedDeclaration, setSelectedDeclaration] = useState(null);
    const openDeclarationErrorModal = (declaration) => {
        setSelectedDeclaration([declaration]);
        setOpenModal(true);
    }
    useEffect(() => {
        api.get('declaration-erros/list-errors-declaration').then(response => {
            setListErrors(response.data)
        })
    }, []);
    console.log(listErrors)

    const handlePageClick = (event) => {
            setCurrentPage(event.selected);
        };

        const offset = currentPage * itemsPerPage;
        const currentPageData = listErrors.slice(offset, offset + itemsPerPage);

        return (
            <>
                <ModalErrorDeclaration isOpen={openModal} errorsDeclaration={selectedDeclaration}
                                       onClose={() => setOpenModal(false)}/>

                {listErrors.length === 0 && (
                    <div className="flex items-center justify-center h-screen">
                        <h1 className="text-2xl">Nenhuma declaração encontrada</h1>
                    </div>
                )}

                <div className="mx-auto bg-white shadow-md rounded-lg p-4 overflow-x-auto"
                     style={{maxWidth: '1100px'}}>
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-3 px-6 text-left">CNPJ</th>
                            <th className="py-3 px-6 text-left">Razão Social</th>
                            <th className="py-3 px-6 text-left">Início Competência</th>
                            <th className="py-3 px-6 text-left">Fim Competência</th>
                            <th className="py-3 px-6 text-left">Código Município</th>
                            <th className="py-3 px-6 text-left">Ações</th>
                            <th className="py-3 px-6 text-left">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentPageData.map((item, index) => (
                            <tr key={index} className="border-b">
                                <td className="py-3 px-6 text-left">{item.cnpj}</td>
                                <td className="py-3 px-6 text-left">{item.razao_social}</td>
                                <td className="py-3 px-6 text-left">{item.ano_mes_incio_competencia}</td>
                                <td className="py-3 px-6 text-left">{item.ano_mes_fim_competencia}</td>
                                <td className="py-3 px-6 text-left">{item.codigo_municipio}</td>
                                <td className="py-3 px-6 text-left relative">
                                    <button
                                        onClick={() => openDeclarationErrorModal(item.ErrosDeclaracoes)}
                                        className="block w-full text-left px-4 py-2 text-white rounded-lg bg-black hover:bg-gray-100"
                                    >
                                        Detalhes
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <ReactPaginate
                        previousLabel={'Anterior'}
                        nextLabel={'Próxima'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(listErrors.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'flex justify-center mt-4 space-x-2'}
                        pageClassName={'mx-1'}
                        pageLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
                        previousClassName={'mx-4'}
                        previousLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
                        nextClassName={'mx-4'}
                        nextLinkClassName={'px-4 py-2 border rounded-lg text-blue-600 hover:bg-gray-200'}
                        activeClassName={'text-gray'}
                        activeLinkClassName={'px-4 m-2 py-2 border rounded-lg text-white bg-black'}
                    />
                </div>
            </>
        );
    }
export default DeclarationErrors;