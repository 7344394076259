import Sidebar from "../components/Sidebar/Sidebar";
import React from "react";
import FormRegisterCityHall from "../components/Forms/FormRegisterCityHall";

const CreateCityHall = () => {
    return (
        <>
            <Sidebar/>
            <div>
        <FormRegisterCityHall />
            </div>
        </>
    )
}

export default CreateCityHall