/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import api from "lib/api";
import { jwtDecode } from "jwt-decode";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [token, setToken] = React.useState({});
  const [decodedToken, setDecodedToken] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    const decodedToken = jwtDecode(token);
    setDecodedToken(decodedToken);
  }, []);

  console.log(decodedToken.role);

  const handleLogout = async (e) => {
    e.preventDefault();
  
    localStorage.removeItem('token');
    window.location.href = "/login";
  };

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            Sistema Desif
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
              className={
                  "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                  collapseShow
              }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                      className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                  >
                    Sistema Desif
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                    type="text"
                    placeholder="Search"
                    className="border-0 px-3 py-2 h-12 border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            <hr className="my-4 md:min-w-full"/>

            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Usuarios
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/list-users") !== -1
                            ? "text-lightBlue-500 hover:text-lightBlue-600"
                            : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to="/list-users"
                >
                  <i
                      className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/list-users") !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                      }
                  ></i>{" "}
                  Usuarios
                </Link>
              </li>
            </ul>

              {(decodedToken.role == "GERENTE" || decodedToken.role == "ADMINISTRADOR") && (
                  <>
                    {/* Divider */}
                    <hr className="my-4 md:min-w-full"/>
                    {/* Heading */}
                    <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                      Declarações
                    </h6>
                    {/* Navigation */}

                    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                      <li className="items-center">
                        <Link
                            className={
                                "text-xs uppercase py-3 font-bold block " +
                                (window.location.href.indexOf("/admin/declaracoes") !== -1
                                    ? "text-lightBlue-500 hover:text-lightBlue-600"
                                    : "text-blueGray-700 hover:text-blueGray-500")
                            }
                            to="/admin/declaracoes"
                        >
                          <i
                              className={
                                  "fas fa-table mr-2 text-sm " +
                                  (window.location.href.indexOf("/admin/tables") !== -1
                                      ? "opacity-75"
                                      : "text-blueGray-300")
                              }
                          ></i>{" "}
                          Enviar DECLARAÇÕES
                        </Link>
                      </li>

                      <li className="items-center">
                        <Link
                            className={
                                "text-xs uppercase py-3 font-bold block " +
                                (window.location.href.indexOf("/admin/list-declarations") !== -1
                                    ? "text-lightBlue-500 hover:text-lightBlue-600"
                                    : "text-blueGray-700 hover:text-blueGray-500")
                            }
                            to="/admin/list-declarations"
                        >
                          <i
                              className={
                                  "fas fa-map-marked mr-2 text-sm " +
                                  (window.location.href.indexOf("/admin/list-declarations") !== -1
                                      ? "opacity-75"
                                      : "text-blueGray-300")
                              }
                          ></i>{" "}
                          Declarações
                        </Link>
                      </li>
                      <li className="items-center">
                        <Link
                            className={
                                "text-xs uppercase py-3 font-bold block " +
                                (window.location.href.indexOf("/declarations-errors") !== -1
                                    ? "text-lightBlue-500 hover:text-lightBlue-600"
                                    : "text-blueGray-700 hover:text-blueGray-500")
                            }
                            to="/declarations-errors"
                        >
                          <i
                              className={
                                  "fa-solid fa-circle-exclamation mr-2 text-sm " +
                                  (window.location.href.indexOf("/declarations-errors") !== -1
                                      ? "opacity-75"
                                      : "text-blueGray-300")
                              }
                          ></i>{" "}
                          Declarações Incorretas
                        </Link>
                      </li>
                    </ul>
                  </>
              )}

              {/* Navigation */}
              {(decodedToken.role === "PREFEITURA" || decodedToken.role === "ADMINISTRADOR") && (
                  <>
                    <ul>
                      {/* Navigation */}
                      <li className="items-center">
                        <Link
                            className={
                                "text-xs uppercase py-3 font-bold block " +
                                (window.location.href.indexOf("/admin/list-declarations") !== -1
                                    ? "text-lightBlue-500 hover:text-lightBlue-600"
                                    : "text-blueGray-700 hover:text-blueGray-500")
                            }
                            to="/admin/list-declarations"
                        >
                          <i
                              className={
                                  "fas fa-map-marked mr-2 text-sm " +
                                  (window.location.href.indexOf("/admin/list-declarations") !== -1
                                      ? "opacity-75"
                                      : "text-blueGray-300")
                              }
                          ></i>{" "}
                          Validar Declarações
                        </Link>
                      </li>
                    </ul>

                    {/* Divider */}
                    <hr className="my-4 md:min-w-full"/>
                    {/* Heading */}
                    <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                      Plano de contas
                    </h6>

                    {/* Navigation */}

                    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                      <li className="items-center">
                        <Link to="/list-plan-cosif"
                              className={
                                  "text-xs uppercase py-3 font-bold block " +
                                  (window.location.href.indexOf("/list-plan-cosif") !== -1
                                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                                      : "text-blueGray-700 hover:text-blueGray-500")
                              }
                        >
                          <i className="fas fa-table mr-2 text-sm"></i>{" "}
                          Plano de Contas
                        </Link>
                      </li>
                      <li className="items-center">
                        <Link to="/admin/configured-plans"
                              className={
                                  "text-xs uppercase py-3 font-bold block " +
                                  (window.location.href.indexOf("/admin/configured-plans") !== -1
                                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                                      : "text-blueGray-700 hover:text-blueGray-500")
                              }
                        >
                          <i className="fas fa-table mr-2 text-sm"></i>{" "}
                          Planos Cosif Configurados
                        </Link>
                      </li>
                      <li className="items-center">
                        <Link to="/admin/config-cosif"
                              className={
                                  "text-xs uppercase py-3 font-bold block " +
                                  (window.location.href.indexOf("/admin/config-cosif") !== -1
                                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                                      : "text-blueGray-700 hover:text-blueGray-500")
                              }
                        >
                          <i className="fas fa-table mr-2 text-sm"></i>{" "}
                          Configurar Plano Cosif
                        </Link>
                      </li>
                    </ul>
                  </>
              )}


              {(decodedToken.role === "PREFEITURA" || decodedToken.role === "ADMINISTRADOR") && (
                  <>
                    {/* Divider */}
                    <hr className="my-4 md:min-w-full"/>
                    <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                      <li className="items-center">
                        <Link
                            className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                            to="/register"
                        >
                          <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                          Credenciamento
                        </Link>
                      </li>
                    </ul>
                  </>
              )}
              {(decodedToken.role === "PREFEITURA" || decodedToken.role === "ADMINISTRADOR") && (
                  <>
                    {/* Divider */}
                    <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                      <li className="items-center">
                        <Link
                            className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                            to="/create-city-hall"
                        >
                          <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                          Cadastar prefeitura
                        </Link>
                      </li>
                    </ul>
                  </>
              )}

            {(decodedToken.role === "PREFEITURA" || decodedToken.role === "ADMINISTRADOR") && (
                <>
                  {/* Divider */}
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li className="items-center">
                      <Link
                          className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                          to="/create-calleds"
                      >
                        <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                        Abrir chamado
                      </Link>
                    </li>
                  </ul>
                </>
            )}

            {(decodedToken.role === "ADMINISTRADOR") && (
                <>
                  {/* Divider */}
                  <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li className="items-center">
                      <Link
                          className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                          to="/calleds"
                      >
                        <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                        Chamados
                      </Link>
                    </li>
                  </ul>
                </>
            )}
              {/* Heading */}

              {/* Divider */}

              <div className="md:hidden">
                <button onClick={handleLogout}
                        className="border-2 border-black text-blueGray-500 px-4 py-2 rounded hover:bg-red-600"><i
                    className="fa-solid fa-arrow-right-from-bracket fa-rotate-180"></i></button>
              </div>

          </div>

          <div className="hidden md:block">
            <button onClick={handleLogout}
                    className="border-2 border-black text-blueGray-500 px-4 py-2 rounded hover:bg-red-600"><i
                className="fa-solid fa-right-from-bracket fa-rotate-180"></i></button>
          </div>
        </div>
      </nav>
    </>
);
}
