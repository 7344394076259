import EditUserTable from "../views/user/EditUserTable";
import Sidebar from 'components/Sidebar/Sidebar.js'

export default function ListUsers(){
    return (
      <>
        <Sidebar />
        <div className="md:ml-64 bg-blueGray-200 h-screen ">
          <EditUserTable />
        </div>
      </>
    )
}