import axios from 'axios';
import Swal from 'sweetalert2';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "https://api.gestaodesif.com", // TODO: Have to get the URL from the environment variables. 
  headers: {
    "Allow-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Você não tem permissão para acessar esta página.",
      }).then(() => {
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    return Promise.reject(error);
  }
);

export default api;
