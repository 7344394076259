import Sidebar from 'components/Sidebar/Sidebar'
import React from 'react'
import ConfigCosifModal from 'views/plan-cosif/ConfigCosifModal'

const ConfigCosif = () => {
  return (
    <>
    <Sidebar />
    <div className="relative md:ml-64 bg-blueGray-100 h-screen">
      <ConfigCosifModal />
    </div>
    </>
    )
}

export default ConfigCosif
