import FormDeclaration from 'components/Forms/FormDeclaration.js'
import Sidebar from 'components/Sidebar/Sidebar.js'

function CreateDeclaration() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 h-screen">
         <FormDeclaration/>
      </div>
    </>
  )
}

export default CreateDeclaration