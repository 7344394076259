import React from "react";
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundColor: "#000000",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Sistema fácil e moderno
                  </h1>
                  <p className="mt-4 text-xl text-blueGray-200">
                    Gerencie as declarações de seu municipio com o Gestão DESIF<span className="text-red-500">.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                      <i className="fas fa-cogs"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Automação Eficiente</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Nosso sistema automatiza o processo de gestão de declarações,
                      reduzindo o tempo e os erros associados ao processamento manual.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                      <i className="fas fa-desktop"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Interface Intuitiva</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Com uma interface amigável e intuitiva, facilitamos a navegação e o uso,
                      permitindo que qualquer pessoa possa utilizar o sistema.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                      <i className="fas fa-lock"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Segurança e Confiabilidade</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      A segurança dos dados é nossa prioridade.
                      Proteja seus dados com tecnologias de criptografia avançada.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div>

                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal text-black">
                  O que é DESIF e para que serve?
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  A Declaração Eletrônica de Serviços de Instituições Financeiras (DES-IF) é um documento fiscal cujo objetivo é registrar a apuração do Imposto
                  Sobre Serviços de Qualquer Natureza (ISSQN) e as operações das Instituições Financeiras autorizadas a funcionar pelo Banco Central (BACEN).
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  A geração da DES-IF é feita pela instituição financeira através da extração de dados dos seus sistemas próprios ou lançamento manual no portal da prefeitura.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words w-full mt-12 rounded-lg ">
                  <img
                    alt="..."
                    src="https://blush.design/api/download?shareUri=7gS4Lw7VnKXz9ENf&w=800&h=800&fm=png"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg"
                  src="https://blush.design/api/download?shareUri=cR9Tlv8k2&c=Skin_0%7Efbd2ae&w=800&h=800&fm=png"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div>

                  </div>
                  <h3 className="text-3xl font-semibold text-black">O que é Abrasf e o qual a relação com a DESIF?</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    A Abrasf é a sigla para Associação Brasileira dos Secretários de Finanças das Capitais.
                    É uma organização nacional sem fins lucrativos criada para aperfeiçoar a gestão financeira e tributária dos municípios.
                    A Abrasf tem trabalhado para simplificar e otimizar os processos das prefeituras como é o caso da emissão da NFSe e a DESIF.
                    O Gestão DESIF utiliza a última versão disponibilizada pela Abrasf, que é a 3.1.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-black">
          <div className="container mx-auto px-4 lg:pt-20 lg:pb-64">

          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-black">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold text-black">
                      Ficou alguma dúvida?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Preencha este formulário e responderemos em até 24 horas.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Nome
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="cidade"
                      >
                        Cidade
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Cidade"
                      />
                    </div>
                    
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="telefone"
                      >
                        Telefone
                      </label>
                      <input
                        type="phone"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Telefone"
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-black text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
