import React from "react";
import api from "../../lib/api";
import Swal from "sweetalert2";

export function ModalCalleds({isOpen, called, onClose}) {

    console.log(called);
    return (
        <>
            {isOpen && (
                <div
                    className="fixed inset-0 z-50 flex w-full items-center justify-center h-screen bg-black opacity"
                    style={{backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(2px)'}}
                >
                    <div className="relative my-6 mx-auto" style={{maxWidth: '1000px'}}>
                        <div
                            className="border-0 rounded-lg p-10 shadow-lg relative flex flex-col bg-white outline-none w-full h-full focus:outline-none max-h-full">
                            {/*header*/}
                            <div
                                className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">Chamado</h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={onClose}
                                >
                                    <span
                                        className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto overflow-y-auto ml-6  w-96"
                                 style={{maxHeight: '400px', margin: '0px 10px'}}>
                                <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                    <div className="bg-white items-center  w-96 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900"
                                                    id="modal-headline">
                                                    Chamado - {called[0].titulo}
                                                </h3>
                                                <div className="mt-2 items-center m-auto flex justify-center ">
                                                    <div className="flex flex-col" style={{gap: '10px'}}>
                                                        {called[0] && (
                                                            <div className="flex flex-col justify-between border-b-2 border-gray-200 p-2" style={{gap: '20px'}}>
                                                                <div className="flex text-left border p-2 rounded">
                                                                    <span className="text-sm font-semibold">{called[0].descricao}</span>
                                                                </div>
                                                                <div className="flex flex-col text-center">
                                                                    <h3>Solução</h3>
                                                                    <p className="text-sm font-semibold"> {called[0].resposta}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            onClick={onClose}
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        >
                                            Fechar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
