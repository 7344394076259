import React, { useEffect, useState } from 'react';
import api from 'lib/api';

const PlanCosifTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('plan-cosif');
        setData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, []);

  // const openDeclarationModal = (declaration) => {
  //   setSelectedDeclaration([declaration]);
  //   setOpenModal(true);
  // };

  return (
    <>
      {/* quando estiver vazio retorne isso */}
      {data.length === 0 && (
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-2xl">Nenhuma declaração encontrada</h1>
        </div>
      )}



      <div className="mx-auto bg-white shadow-md rounded-lg p-4" style={{ maxWidth: '1100px' }}>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-3 px-6 text-left">Codigo da conta</th>
              <th className="py-3 px-6 text-left">Codigo da conta superior</th>
              <th className="py-3 px-6 text-left">Numero da conta</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-3 px-6 text-left">{item.codigo_conta}</td>
                <td className="py-3 px-6 text-left">{item.codigo_conta_superior}</td>
                <td className="py-3 px-6 text-left">{item.numero_conta}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PlanCosifTable;
