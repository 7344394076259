import React, {useState} from "react";
import api from "../../lib/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const FormRegisterCityHall = () => {

    const [cidades, setCidades] = useState([]);

    const listCidades = async () => {
        await api.get("/cities").then((response) => {
            setCidades(response.data);
            console.log("Bancos:", response.data);
        });
    };

    React.useEffect(() => {
        listCidades();
    }, []);

    const [formData, setFormData] = useState({
        nome_prefeitura: "",
        cpf_cnpj: "",
        inscricao_estadual: null,
        inscricao_municipal: null,
        id_pais: 1,
        cep: "",
        estado: "",
        id_cidade: 1,
        bairro: "",
        rua: "",
        numero_casa: 123,
        complemento: null,
        email: "",
        telefone: "",
        created_at: "",
        ativo: "",
        codigo_ddd: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        await api
            .post("/city-hall", formData)
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Usuário registrado com sucesso!",
                    text: "Você será redirecionado para a página de login",
                }).then(() => {
                    window.location.href = "/register";
                });
            })
            .catch((error) => {
                console.error("Erro ao fazer login:", error);

                const swalError = withReactContent(Swal);
                return swalError.fire({
                    icon: "error",
                    title: "Erro ao registrar",
                    text: error.response.data.message,
                });
            });
    };

    return (
        <div className="flex items-center justify-center min-h-screen mt-10 bg-gray-100">
            <div className="w-full px-4 lg:w-6/12">
                <div
                    className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
                    <div className="px-6 py-6 mb-0 rounded-t">
                        <div className="mb-3 text-center">
                            <h6 className="text-sm font-bold text-blueGray-500">
                                Cadastar prefeitura
                            </h6>

                            <div className="text-center btn-wrapper">
                                <img
                                    src={require("assets/img/desif.png")}
                                    alt="..."
                                    className="mx-auto rounded-full"
                                    style={{width: "300px"}}
                                />
                            </div>
                        </div>
                        <hr className="mt-6 border-b-1 border-blueGray-300"/>
                    </div>
                    <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
                        <form  onSubmit={handleSubmit}>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Nome da prefeitura
                                </label>
                                <input
                                    type="text"
                                    name="nome_prefeitura"
                                    value={formData.nome_prefeitura}
                                    onChange={(e) => setFormData({...formData, nome_prefeitura: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Nome da prefeitura"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    CPF/CNPJ
                                </label>
                                <input
                                    type="text"
                                    name="cpf_cnpj"
                                    value={formData.cpf_cnpj}
                                    onChange={(e) => setFormData({...formData, cpf_cnpj: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="CPF/CNPJ"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Inscrição estadual
                                </label>
                                <input
                                    type="text"
                                    name="inscricao_estadual"
                                    value={formData.inscricao_estadual}
                                    onChange={(e) => setFormData({...formData, inscricao_estadual: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Inscrição estadual"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Inscrição municipal
                                </label>
                                <input
                                    type="text"
                                    name="inscricao_municipal"
                                    value={formData.inscricao_municipal}
                                    onChange={(e) => setFormData({...formData, inscricao_municipal: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Inscrição municipal"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    CEP
                                </label>
                                <input
                                    type="text"
                                    name="cep"
                                    value={formData.cep}
                                    onChange={(e) => setFormData({...formData, cep: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="CEP"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Estado
                                </label>
                                <input
                                    type="text"
                                    name="estado"
                                    value={formData.estado}
                                    onChange={(e) => setFormData({...formData, estado: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Estado"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Cidade
                                </label>
                                <select
                                    name="id_cidade"
                                    value={formData.id_cidade}
                                    onChange={(e) => setFormData({...formData, id_cidade: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                >
                                    <option value={null} selected={true}>Selecione uma cidade</option>
                                    {cidades.map((cidade) => (
                                        <option key={cidade.id} value={cidade.id}>
                                            {cidade.sigla_estado} - {cidade.nome}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Bairro
                                </label>
                                <input
                                    type="text"
                                    name="bairro"
                                    value={formData.bairro}
                                    onChange={(e) => setFormData({...formData, bairro: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Bairro"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Rua
                                </label>
                                <input
                                    type="text"
                                    name="rua"
                                    value={formData.rua}
                                    onChange={(e) => setFormData({...formData, rua: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Rua"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Número da casa
                                </label>
                                <input
                                    type="text"
                                    name="numero_casa"
                                    value={formData.numero_casa}
                                    onChange={(e) => setFormData({...formData, numero_casa: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Número da casa"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Complemento
                                </label>
                                <input
                                    type="text"
                                    name="complemento"
                                    value={formData.complemento}
                                    onChange={(e) => setFormData({...formData, complemento: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Complemento"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Email
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Email"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block mb-2 text-xs font-bold text-blueGray-500 uppercase"
                                    htmlFor="grid-password"
                                >
                                    Telefone
                                </label>
                                <input
                                    type="text"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={(e) => setFormData({...formData, telefone: e.target.value})}
                                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                    placeholder="Telefone"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="text-center mt-6">
                                <button
                                    className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-blue-500 rounded shadow outline-none active:bg-blue-600 hover:shadow-lg focus:outline-none"
                                    type="submit"
                                    style={{transition: "all .15s ease"}}
                                >
                                    Registrar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormRegisterCityHall