import Sidebar from "../components/Sidebar/Sidebar";
import FormDeclaration from "../components/Forms/FormDeclaration";
import DeclarationErrors from "../views/declarations/DeclarationErrors";


export function ListDeclarationErrors(){
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100 h-screen">
                <DeclarationErrors/>
            </div>
        </>
    )
}