import React from "react";
import "./Login.css";
import api from "lib/api";
import Swal from "sweetalert2";
import {withMask} from "use-mask-input";


export default function Login() {
    const verifyLogin = () => {
        if (localStorage.getItem("token")) {
            window.location.href = "/admin/list-declarations";
        }
    }
    verifyLogin();

    const [form, setForm] = React.useState({
        cpf: "",
        password: "",
    });

    const handleLogin = async () => {
        const preparedForm = {
            cpf: form.cpf.replace(/\D/g, ""),
            password: form.password,
        }
        try {
            const response = await api.post("/auth/login", preparedForm);

            localStorage.setItem("token", response.data.access_token);

            await Swal.fire({
                icon: "success",
                title: "Login efetuado com sucesso!",
                text: "Você será redirecionado para a página de declarações",
            }).then(() => {
                window.location.href = "/admin/list-declarations";
            });

        } catch (error) {
            console.error(error);
            await Swal.fire({
                icon: "error",
                title: "Erro ao fazer login",
                text: error.response.data.message,
            });
        }
    };

    return (
        <>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full px-4 lg:w-4/12">
                    <div
                        className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
                        <div className="px-6 py-6 mb-0 rounded-t">
                            <div className="mb-3 text-center">
                                <h6 className="text-sm font-bold text-blueGray-500">
                                    Entre no sistema
                                </h6>
                            </div>
                            <div className="text-center btn-wrapper">
                                <img
                                    src={require("assets/img/desif.png")}
                                    alt="..."
                                    className="w-96 mx-auto rounded-full"
                                />
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300"/>
                        </div>
                        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    handleLogin();
                                }}
                            >
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                                        htmlFor="grid-password"
                                    >
                                        CPF
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                        placeholder="999.999.999-99"
                                        ref={withMask({mask: "999.999.999-99"})}
                                        value={form.cpf}
                                        onChange={(event) =>
                                            setForm({...form, cpf: event.target.value})
                                        }
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                                        htmlFor="grid-password"
                                    >
                                        Senha
                                    </label>
                                    <input
                                        type="password"
                                        className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                        placeholder="********"
                                        value={form.password}
                                        onChange={(event) =>
                                            setForm({...form, password: event.target.value})
                                        }
                                    />
                                </div>

                                <div className="mt-6 text-center">
                                    <button
                                        className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                                        type="submit"
                                    >
                                        Entrar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="relative flex flex-wrap mt-6">
                        <div className="w-1/2">
                            <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                className="text-blueGray-800"
                            >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
