import api from 'lib/api';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const ConfigCosifModal = () => {
  const [selectedPlanCosif, setPlancCosif] = useState(null);
  const [selectedInstBank, setInstBank] = useState(null);
  const [planCosifData, setPlanCosifData] = useState([]);
  const [instBankData, setInstBankData] = useState([]);
  const [obrigatory, setObrigatory] = useState(false);

  const handleSelectChange = (event) => {
    setPlancCosif(event.target.value);
  };

  const handleInstBank = (event) => {
    setInstBank(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log('Plano Cosif:', selectedPlanCosif);
    if (!selectedPlanCosif || !selectedInstBank) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Você deve selecionar um Plano Cosif e uma Instituição Bancária!',
      });
      return;
    }
    
    const data = {
      id_plano_conta_cosif: parseInt(selectedPlanCosif),
      id_instituicao_bancaria: parseInt(selectedInstBank),
      id_prefeitura: 1,
      obrigatorio: obrigatory,
    }

    try {
      await api.post('/plan-cosif/config-cosif', data)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Plano Cosif configurado com sucesso!',
      }); 
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Erro ao configurar o Plano Cosif!',
      }).then(() => {
        window.location.reload();
      });
    }
      


    

    console.log('Plano Cosif:', selectedPlanCosif);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const instBankResponse = await api.get('/instituicao-bancaria');
        setInstBankData(instBankResponse.data); // Atualiza o estado com os dados da resposta

        const planCosifResponse = await api.get('/plan-cosif');
        setPlanCosifData(planCosifResponse.data); // Atualiza o estado com os dados da resposta

        // Você pode adicionar lógica adicional aqui, se necessário
      } catch (error) {
        console.error('Erro ao fazer requisição:', error);
        // Lidar com o erro (exibir mensagem ao usuário, etc.)
      }
    }

    fetchData();
  }, []);

  return (
    <div className="container px-4 place-content-center m-auto justify-center">
      <div className="flex content-center items-center justify-center">
        <div className="w-full justify-center m-auto place-content-center items-center mt-20 lg:w-4/12 px-4">
          <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center mb-3">
                <h6 className="text-blueGray-500 text-sm font-bold">
                  Configure o Plano Cosif
                </h6>
              </div>
              <div className="btn-wrapper text-center"></div>
              <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={handleSubmit}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="planCosifSelect"
                  >
                    Escolha o Plano Cosif
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="planCosif"
                    value={selectedPlanCosif}
                    onChange={handleSelectChange}
                    id="planCosifSelect"
                  >
                    <option value={null} selected> Selecione uma opçao</option>
                    {planCosifData.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.numero_conta}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="instBankSelect"
                  >
                    Escolha a Instituição Bancária
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    name="instBank"
                    value={selectedInstBank}
                    onChange={handleInstBank}
                    id="instBankSelect"
                  >
                    <option value={null} selected> Selecione uma opçao</option>
                    {instBankData.map((inst) => (
                      <option key={inst.id} value={inst.id}>
                        {inst.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-center mt-6">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setObrigatory(!obrigatory)}
                  >
                    {obrigatory ? 'Obrigatório' : 'Não Obrigatório'}
                  </button>
                </div>
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Salvar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigCosifModal;
