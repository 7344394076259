import api from "lib/api";
import React from "react";
import Swal from "sweetalert2";

export default function ModalDeclaration({ isOpen, declarations, onClose }) {

  const approveDeclaration = (id) => {
    api.patch('declaration/update-status/' + id, {
      motivo_situacao: 'Aprovado',
      status_situacao: 1,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Declaração aprovada com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        onClose();
      })
      .catch((error) => {
        console.error('Erro ao aprovar a declaração:', error);
      });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex w-full items-center justify-center h-screen bg-black opacity" style={{ backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(2px)' }} >
          <div className="relative my-6 mx-auto" style={{ maxWidth: '1000px' }}>
            <div className="border-0 rounded-lg p-10 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-full">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold">Declarações</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={onClose}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-y-auto ml-6" style={{ maxHeight: '400px', margin: '0px 10px' }}>
                {declarations && declarations.map((declaration, index) => (
                  <div key={index} className="my-4 text-blueGray-500 p-10 text-lg leading-relaxed mr-7">
                    <h2 className="text-2xl font-semibold">Indentificação Declaração</h2>
                    <p className="text-sm mt-2"><strong>CNPJ:</strong> {declaration.cnpj}</p>
                    <p className="text-sm"><strong>Razão Social:</strong> {declaration.razao_social}</p>
                    <p className="text-sm"><strong>Início Competência:</strong> {declaration.ano_mes_incio_competencia}</p>
                    <p className="text-sm"><strong>Fim Competência:</strong> {declaration.ano_mes_fim_competencia}</p>
                    <p className="text-sm"><strong>Código Município:</strong> {declaration.codigo_municipio}</p>

                    {declaration.DeclaracaoRegistro_0400 && (
                      <div className="mt-4">
                        <h2 className="text-2xl font-semibold">Identificação da dependência</h2>
                        {declaration.DeclaracaoRegistro_0400.map((registro, regIndex) => (
                          <div key={regIndex} className="mt-2">
                            <p className="text-sm"><strong>Código Dependência:</strong> {registro.codigo_dependencia}</p>
                            <p className="text-sm"><strong>Indicador Inscrição Municipal:</strong> {registro.indicador_inscricao_municipal}</p>
                            <p className="text-sm"><strong>CNPJ Próprio:</strong> {registro.cnpj_proprio}</p>
                            <p className="text-sm"><strong>Tipo Dependência:</strong> {registro.tipo_dependencia}</p>
                            <p className="text-sm"><strong>Endereço Dependência:</strong> {registro.endereco_dependencia}</p>
                            <p className="text-sm"><strong>CNPJ Responsável:</strong> {registro.cnpj_responsavel}</p>
                            <p className="text-sm"><strong>Código Município:</strong> {registro.codigo_municipio}</p>
                            <p className="text-sm"><strong>Contabilidade Própria:</strong> {registro.contabilide_propria}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    {declaration.DeclaracaoRegistro_0430 && (
                      <div className="mt-4">
                        <h2 className="text-xl font-semibold">Demonstrativo da apuração
                          da receita tributável e do
                          ISSQN mensal devido por Subtítulo</h2>
                        <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                          <div className="flex flex-wrap justify-center" style={{ gap: '10px' }}>
                            {declaration.DeclaracaoRegistro_0430.map((registro, regIndex) => (
                              <div key={regIndex} className="mt-4 border-black border-solid border-2 p-10" style={{ padding: '10px', lineHeight: '3px' }} >
                                <p className="text-sm"><strong>Aliquota ISSQN:</strong> {registro.aliquota_ISSQN}</p>
                                <p className="text-sm"><strong>Base de Cálculo:</strong> {registro.base_calculo}</p>
                                <p className="text-sm"><strong>Código Dependência:</strong> {registro.codigo_dependencia}</p>
                                <p className="text-sm"><strong>Código Tributação Desif:</strong> {registro.codigo_tributacao_desif}</p>
                                <p className="text-sm"><strong>Descriminação Receita Declarada:</strong> {registro.descriminacao_receita_declarada}</p>
                                <p className="text-sm"><strong>Desdobramento Conta Mista:</strong> {registro.desdobramento_conta_mista}</p>
                                <p className="text-sm"><strong>Valor Débito:</strong> {registro.valor_debito}</p>
                                <p className="text-sm"><strong>Subtítulo Analítico:</strong> {registro.subtitulo_analitico}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {declaration.DeclaracaoRegistro_0100 && (
                      <div className="mt-4">
                        <h2 className="text-xl font-semibold">Indentificação Declaração 0100</h2>
                        <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                          <div className="flex flex-wrap justify-center" style={{ gap: '10px' }}>
                            {declaration.DeclaracaoRegistro_0100.map((registro, regIndex) => (
                              <div key={regIndex} className="mt-4 border-black border-solid border-2 p-10" style={{ padding: '10px', lineHeight: '3px' }} >
                                <p className="text-sm"><strong>Número linha:</strong> {registro.numero_linha}</p>
                                <p className="text-sm"><strong>Conta:</strong> {registro.conta}</p>
                                <p className="text-sm"><strong>Des mista:</strong> {registro.des_mista}</p>
                                <p className="text-sm"><strong>Desc Conta:</strong> {registro.desc_conta}</p>
                                <p className="text-sm"><strong>Nome:</strong> {registro.nome}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {declaration.DeclaracaoRegistro_0200 && (
                      <div className="mt-4">
                        <h2 className="text-xl font-semibold">Indentificação Declaração 0200</h2>
                        <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                          <div className="flex flex-wrap justify-center" style={{ gap: '10px' }}>
                            {declaration.DeclaracaoRegistro_0200.map((registro, regIndex) => (
                              <div key={regIndex} className="mt-4 border-black border-solid border-2 p-10" style={{ padding: '10px', lineHeight: '3px' }} >
                                <p className="text-sm"><strong>Tipo registro:</strong> {registro.tipo_registro}</p>
                                <p className="text-sm"><strong>Idto tari:</strong> {registro.idto_tari}</p>
                                <p className="text-sm"><strong>Data vigência:</strong> {registro.dat_vige}</p>
                                <p className="text-sm"><strong>Valor tarifa unitária:</strong> {registro.val_tari_unit}</p>
                                <p className="text-sm"><strong>Valor tarifa percentual:</strong> {registro.val_tari_perc}</p>
                                <p className="text-sm"><strong>Sub titulo:</strong> {registro.sub_titu}</p>
                                <p className="text-sm"><strong>Des mista:</strong> {registro.des_mista}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}


                    {declaration.DeclaracaoRegistro_0300 && (
                      <div className="mt-4">
                        <h2 className="text-xl font-semibold">Indentificação Declaração 0300</h2>
                        <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                          <div className="flex flex-wrap justify-center" style={{ gap: '10px' }}>
                            {declaration.DeclaracaoRegistro_0300.map((registro, regIndex) => (
                              <div key={regIndex} className="mt-4 border-black border-solid border-2 p-10" style={{ padding: '10px', lineHeight: '3px' }} >
                                <p className="text-sm"><strong>Tipo registro:</strong> {registro.tipo_registro}</p>
                                <p className="text-sm"><strong>Número linha:</strong> {registro.numero_linha}</p>
                                <p className="text-sm"><strong>Idto serv:</strong> {registro.idto_serv}</p>
                                <p className="text-sm"><strong>Desc completa serv:</strong> {registro.desc_completa_serv}</p>
                                <p className="text-sm"><strong>Desc Conta:</strong> {registro.desc_conta}</p>
                                <p className="text-sm"><strong>Sub titulo:</strong> {registro.sub_titu}</p>
                                <p className="text-sm"><strong>Des mista:</strong> {registro.des_mista}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={onClose}
                >
                  Fechar
                </button>
                <button
                  className="bg-green-500  active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => declarations.forEach(declaration => approveDeclaration(declaration.id))}
                >
                  Aprovar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
